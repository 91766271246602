// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_URL : 'http://localhost:8000/api', //API LOCALHOST
  API_URL : 'http://v2.appsjf.cl/ApiPosQA/public/api', //API QA V2
  // API_URL : 'http://v2.appsjf.cl/ApiPos/public/api', //API PROD V2
  // API_URL : 'https://v3.sanjoseapps.cl/ApiBackendPOS/public/api', //API PROD V3
  // API_URL : 'https://v3.sanjoseapps.cl/ApiBackendQA_POS/public/api', //API PROD V3
  SERVER_URL : 'http://qas-tomcat8.expled.cl:8082', //QA SERVER
  // SERVER_URL : ' https://baika-prd.goplicity.com', //PROD SERVE
  MAIN_URL : '/login'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
